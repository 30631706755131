// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Aktuellt',
          component: () => import('@/views/aktuellt/Index.vue'),
        },
        {
          path: 'om',
          name: 'Om oss',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'johan',
          name: 'Johan',
          component: () => import('@/views/johan/Index.vue'),
          meta: { src: require('@/assets/johan_cv_1.jpg') },
        },
        {
          path: 'kerstin',
          name: 'Kerstin',
          component: () => import('@/views/kerstin/Index.vue'),
          meta: { src: require('@/assets/kerstin_cv_4.jpg') },
        },
        {
          path: 'teater',
          name: 'Teater',
          component: () => import('@/views/teater/Index.vue'),
          meta: { src: require('@/assets/teater.jpg') },
        },
        {
          path: 'film',
          name: 'Film',
          component: () => import('@/views/film/Index.vue'),
          meta: { src: require('@/assets/film.jpg') },
        },
        {
          path: 'shop',
          name: 'Shop',
          component: () => import('@/views/shop/Index.vue'),
          meta: { src: require('@/assets/shop.jpg') },
        },
        {
          path: 'kontakt',
          name: 'Kontakt',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/contact.jpg') },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
