<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <base-text-field label="Namn" />

    <base-text-field label="E-post" />

    <base-text-field label="Ämne" />

    <base-textarea
      class="mb-6"
      label="Ditt meddelande"
    />

    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      href="mailto:info@johker.se?subject=Komma%20i%20kontakt"
      outlined
      target="_blank"
    >
      Skicka
    </base-btn>
  </div>
</template>

<script>
  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },
  }
</script>
